@import '../../assets/scss/theme/colors';
@import '../../assets/scss/theme/global';
@import '../../assets/scss/theme/media';

.main-menu-wrapp {
  background-color: $primary-color;
  height: 115px;
  width: 100%;
  padding-top: $spacing-03;
  padding-bottom: $spacing-03;

  @include query($media-desktop-sm) {
    height: fit-content;
    padding: 0;
  }
}

.main-menu {
  background-color: $primary-color;
  left: 50%;
  padding: 0 !important;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  z-index: 16;

  @include query($media-tablet) {
    padding: 0 $layout-02 !important;
  }

  @include query($media-desktop-sm) {
    align-items: stretch;
    border-radius: 0;
    box-shadow: none;
    padding: 0 15px !important;
    position: relative;
    width: 960px;
  }

  @include query($media-desktop-lg) {
    width: 1140px;
  }

  .navbar-brand {
    @include query($media-desktop-sm) {
      padding: $spacing-04 0;
    }
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-toggler-icon {
    background-image: url('../../assets/images/icon-menu.png');
  }

  .navbar-nav {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 !important;
    padding-bottom: $layout-02;
    width: 100%;

    @include query($media-desktop-sm) {
      align-items: flex-end;
      padding-bottom: $layout-03;
    }

    a {
      color: #fff;
      font: normal 400 17px/20px 'Open Sans', sans-serif;
      letter-spacing: 0.001em;
      text-align: left;

      &:hover {
        text-decoration: none;
      }

      @include query($media-desktop-sm) {
        margin-top: $layout-03;
        font-size: 14px;

        &:hover {
          color: $tertiary-color;

          & ~ span svg path {
            fill: $tertiary-color;
          }
        }
      }

      @include query($media-desktop-lg) {
        font-size: 17px;
      }
    }

    .collapse-dropdown-btn {
      svg {
        height: 12px;
        margin-left: $layout-01;
        transform: rotate(90deg) translateY(25%);
        transition: 0.3s ease-in-out;
        width: 8px;

        @include query($media-desktop-sm) {
          margin-bottom: -3px;
        }
      }

      &.open {
        svg {
          transform: rotate(-90deg) translateY(-25%);

          @include query($media-desktop-sm) {
            transform: rotate(90deg) translateY(25%);
          }
        }
      }
    }

    a.nav-link,
    .dropdown-item {
      color: #fff;
      padding: 0;
      padding-bottom: $spacing-07;
      text-align: center;

      @include query($media-desktop) {
        padding-bottom: 0;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .dropdown-menu {
    background-color: $primary-color;
    border-color: #fff;
  }

  .navbar-collapse {
    transition: display 0s 0.2s;

    &.collapse,
    &.collapsing {
      align-items: flex-start;
      flex-basis: initial;
      flex-direction: column-reverse;
      flex-grow: 0;
      height: 100%;
      justify-content: flex-end;
      position: relative;
      padding-left: $layout-01;
      padding-right: $layout-01;

      @include query($media-desktop-sm) {
        display: block;
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
      }
    }

    &.show {
      display: flex;

      &:first-of-type {
        padding-top: $layout-02;

        @include query($media-desktop-sm) {
          padding-top: 0;
        }
      }
    }
  }

  .navbar-collapse {
    padding: 0;

    &:not(&:last-child) {
      @include query($media-desktop-sm) {
        margin-left: $layout-03;
      }
    }

    &:hover {
      .submenu {
        display: block;
        opacity: 1;
      }
    }
  }

  .branding-flat {
    display: none;
    height: 90px;
    object-fit: cover;
    width: 133px;
    padding-left: 15px;

    @include query($media-desktop-sm) {
      padding-left: 0;
    }
  }

  .branding-color {
    padding-left: 15px;
    display: none;
    width: 133px;

    @include query($media-desktop-sm) {
      width: 200px;
    }
  }

  &:not(.hero-slider__slide) {
    .branding-flat {
      display: inline-block;
    }
  }
}

.main-menu__items {
  position: relative;
  text-align: center;
  width: 100%;

  @include query($media-desktop-sm) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
  }
}

.hero-slider__slide {
  .main-menu-wrapp {
    background-color: transparent;
    height: 0 !important;
    margin-bottom: 0;
    padding: 0;

    .branding-flat {
      display: none;
    }

    .branding-color {
      display: inline-block;
    }
  }

  .main-menu {
    & {
      @include query($media-desktop-sm) {
        background-color: transparent;
      }
    }
  }
}

.main-menu__item-active {
  opacity: 1 !important;
  font-weight: bold !important;
}

@import '../../../assets/scss/theme/media';

.button-play {
  align-items: center;
  background: linear-gradient(157.15deg, #e9052d -3.28%, #8e2637 68.94%);
  border-radius: 56px;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2), 0px 11px 28px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  width: 40px;
  z-index: 1;

  &:hover {
    opacity: 0.9;
  }

  @include query($media-tablet) {

    &:not(.keep-sm) {
      height: 113px;
      width: 113px;
    }
  }
}

.slick-slide {

  button.button-play {

    img {
      height: 10px;
      margin: 0 0 0 2px;
      padding: 0;
      width: 10px;

      @include query($media-tablet) {
        &:not(.keep-sm) {
          height: unset;
          margin: 0 auto;
          padding-left: 8px;
          width: unset;
        }
      }
    }
  }
}

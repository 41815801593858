//
// Cover - Style 1 - Variation 3
//

.shards-hero-s1--3 {
  @include media-breakpoint-down(md) {
    .shards-hero__content .display-2 {
      font-size: 3.75rem;
    }
  }
}

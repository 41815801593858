// Hero - Style 5 - Variation 1

.shards-hero-s5--1 {
  @include media-breakpoint-down(sm) {

    form,
    form input,
    form button {
      width: 100% !important;
    }
  }
}

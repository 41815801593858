@import '../../assets/scss/theme/colors';
@import '../../assets/scss/theme/global';
@import '../../assets/scss/theme/media';

.social_icons {
  list-style: none;
  margin: $layout-04 0 0 0;
  padding: 0;
  text-align: center;

  img {
    height: 20px;
    max-width: 30px;
    margin-right: 8px;

    @include query($media-tablet) {
      margin-right: $layout-01;
    }   
  }

  span {
    font-size: 14px;
    padding-top: $spacing-02;
  }

  li {
    display: inline-block;

    &:not(&:first-child) {
      margin-left: $layout-01;

      @include query($media-tablet) {
        margin-left: $layout-04;
      }   
    }

    a {
      color: $primary-color;
    }
  }
}
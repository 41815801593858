//
// Call to Action - Variation 1
//

.shards-cta--1 {
  background-color: $cta-v1-background-color;
  background-size: $cta-v1-background-size;
  background-repeat: $cta-v1-background-repeat;
  background-position: $cta-v1-background-position;

  @include media-breakpoint-down(sm) {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $white;
      opacity: .7;
    }
  }
}

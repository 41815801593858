@import '../../../assets/scss/theme/global';
@import '../../../assets/scss/theme/media';

.video-card {
  border-radius: 15px;
  box-shadow: 0px 6px 19px rgba(90, 97, 105, 0.1);

  .button-play {
    height: 50px;
    width: 50px;

    img {
      height: 17px;
      margin-left: 2px;
      width: 14px;
    }
  }

  .cover-img {
    &.no-content img {
      border-radius: 15px;
    }

    img {
      border-radius: 15px 15px 0 0;
      object-fit: cover;
      height: 190px;
      width: 260px;
    }
  }

  .video-card-body {
    padding: $spacing-04 $spacing-06 $spacing-06 $spacing-06;

    h3 {
      color: #000;
      font: normal normal 700 19px/24px 'Open Sans', sans-serif;
      letter-spacing: 0.0015rem;
    }

    p {
      margin: 0;
    }
  }

  &.featured-video-card {
    height: 216px;
    margin-bottom: $layout-03;
    overflow: hidden;
    position: relative;

    @include query($media-tablet) {
      height: 450px;
    }

    .cover-img {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));

      img {
        border-radius: 15px;
        height: 100%;
        width: 100%;
        filter: brightness(0.75);
      }
    }

    .video-card-body {
      left: 1.5rem;
      bottom: 0;
      padding: 1rem 0;
      position: absolute;

      @include query($media-tablet) {
        padding: $spacing-04 $spacing-06 $spacing-08 $spacing-06;
      }

      h3,
      p {
        color: #fff;
      }

      h3 {
        font: normal normal 700 16px/21px 'Montserrat', sans-serif;

        @include query($media-tablet) {
          font: normal normal 700 30px/40px 'Montserrat', sans-serif;
        }
      }

      p {
        display: none;
        font-size: 16px;
        line-height: 24px;

        @include query($media-tablet) {
          display: block;
        }
      }

      @include query($media-tablet) {
        width: 50%;
      }
    }
  }
}

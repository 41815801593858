// Shadows

// Text Shadows
.text-shadow {
  @for $value from 1 through 5 {
    &--small-#{$value} {
      text-shadow: 0 2px 2px rgba($text-shadow-color, ($value / 15));
    }
  }

  @for $value from 1 through 5 {
    &--large-#{$value} {
      text-shadow: 0 3px 6px rgba($text-shadow-color, ($value / 15));
    }
  }
}

// General Box Shadows
.box-shadow {
  @for $value from 1 through 5 {
    &-small--#{$value} {
      box-shadow: 0 2px 5px rgba($blueish-grey, ($value / 15)) !important;
    }
  }

  @for $value from 1 through 5 {
    &-large--#{$value} {
      box-shadow: 0 3px 7px rgba($blueish-grey, ($value / 15));
    }
  }
}

// Remove box shadows
.no-shadows {
  box-shadow: none !important;
  text-shadow: none !important;
}

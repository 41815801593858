@import './global';
@import './media';


form {
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
  width: 100%;

  @include query($media-tablet) {
    width: 555px;
  }
}

label,
input,
input::placeholder,
select {
  font: normal normal 400 14px/21px 'Open Sans', sans-serif !important; // !important is use beacuse some inputs comes from modules of react or bootstrap with owns styles
  letter-spacing: 0.005em;
}

input,
textarea {
  color: $tertiary-gray-color;
  border-radius: $radius-sm;
  border: 1px solid  #EAEAEA;
  padding: $spacing-04 $spacing-05;
  position: relative;

  &:focus {
    outline-color: $quaternary-accent-color;
    border-radius: $radius-sm;
  }
}

.select-wrapper {
  
  &::after {
    content:"";
    background-image: url("../../../../src/assets/images/icons/arrow-select.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 9px ;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    transition: .5s ease-out;
    width: 15px;
    z-index: -2;
  }
}

select {
  -moz-appearance:none !important;
  -webkit-appearance:none !important;
  appearance:none !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: $box-shadow-primary !important;
  color: #000 !important;
  height: auto !important;
  padding: $spacing-04 $spacing-08 $spacing-04 $spacing-07 !important;
  position: relative;
}

fieldset {
  display: flex;
  flex-direction: column;
  margin-bottom: $layout-01;
  width: 100%;

  @include query($media-tablet) {
    width: 260px;
  }

  input,
  label {
    width: 100%;

    @include query($media-tablet) {
      min-width: 260px;
    }
  }

  textarea {
    width: 100%;

    @include query($media-desktop-sm) {
      width: 555PX;
    }
  }
}

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
  width: 32px;
  height: 32px;
}

input[type="submit"] {
  background: $gradient-principal;
  border-radius: $radius-lg;
  border: none;
  box-shadow: $box-shadow-primary;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: $layout-01;
  min-width: max-content;
  padding: $spacing-03 $spacing-06;
  text-decoration: none;
  width: 100%;

  a {
    text-decoration: none;
  }

  &:hover {
    background: $gradient-secondary;
    color: white;
    text-decoration: none;
    transition: background 0.5s;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input--suscribe {
  height: 42px ;
  font-size: 14px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &:-moz-focus {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

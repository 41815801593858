// Hero - Style 2 - Variation 6

.shards-hero-s2--6 .shards-hero__content-image {
  position: $hero-s2-v6-content-image-position;
  top: $hero-s2-v6-content-image-top;
  bottom: $hero-s2-v6-content-image-bottom;
  right: $hero-s2-v6-content-image-right;
  height: 100vh;
  max-height: 900px;
}

.card-bg-resize {
  background-size: 100%;
  background-repeat: no-repeat;
  transition: background-size 1s, filter 1s ease-in-out;

  @include query($media-desktop-sm) {
    &:hover{
      background-size: 130% !important;
    }
  }

}

.card-img-resize {

  .lazyload-wrapper  {
    overflow: hidden;
    height: 100%;
    max-height: 320px;
    border-radius: $radius-md $radius-md 0 0;
  }

  img {
    transform-origin: 50% 50%;
    transition: transform 1s, filter 1s ease-in-out;

    @include query($media-desktop-sm) {
      &:hover {
        transition: 2s;
        transform: scale(1.3);
      }
    }

  }
}

.hover-colorize {
  transition: filter .3s ease-out;
  
  @include query($media-desktop-sm) {
    filter: grayscale(100%);
    
    &:hover {
      filter: grayscale(0);
    }
  }

}

.bg-attach {
  background: no-repeat fixed;
  background-size: cover;
}  
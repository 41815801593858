@import '../../assets/scss/theme/global';
@import '../../assets/scss/theme/media';
@import '../../assets/scss/theme/colors';

.hero-slider {
  margin-bottom: -100px;
  position: relative;
}

.hero-slider__slide {
  min-height: 800px;
  position: relative;

  img {
    object-fit: cover;
    object-position: top;
  }

  h2,
  p {
    text-align: left;
  }

  .carousel slide {
    box-shadow: none;
  }

  .carousel-caption {
    left: 0;
    right: 0;
    top: 45%;
    transform: translateY(-50%);

    @include query(375px) {
      top: 50%;
    }
  }

  .carousel-indicators {
    z-index: 14;
    margin-bottom: 30%;

    @include query(640px) {
      margin-bottom: 115px;
    }

    @include query($media-tablet) {
      margin-bottom: 115px;
    }

    li {
      border-radius: 50%;
      height: 10px;
      margin-bottom: auto;
      margin-top: auto;
      width: 10px;

      &.active {
        height: 20px;
        width: 20px;
      }

      &:not(&:last-child) {
        margin-right: $layout-01;
      }
    }

    img {
      object-fit: cover;
    }

    h2,
    p {
      text-align: left;
    }

    .carousel slide {
      box-shadow: none;
    }

    .carousel-caption {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.hero-slider__img {
  max-height: 100vh;
  min-height: 800px;
  object-fit: cover;
  width: 100%;
}

.hero-slider__title {
  font-size: 48px;
  font: normal normal 500 50px/60px;
  max-width: 577px;
  text-align: left;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.625793), 0px 10px 45px rgba(0, 0, 0, 0.3);

  @include query($media-tablet) {
    font-size: 90px;
    line-height: 100px;
  }
}

.hero-slider__sub {
  font: normal normal 400 30px/40px 'Montserrat', sans-serif;
  margin-bottom: $layout-02;
  text-align: left;

  @include query($media-desktop-lg) {
    width: 60%;
  }
}

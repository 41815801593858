@import '../../../assets/scss/theme/media';

.video-lightbox {
  background-color: #fff;
  border: none;
  cursor: default !important;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 16;

  .button--close {
    padding: 0;
    position: absolute;
    right: 20px;
    top: 70px;

    @include query($media-tablet) {
      right: 30px;
      top: 30px;
    }
  }

  .container {
    height: 50%;
    padding: 0;

    @include query($media-tablet) {
      height: 80%;
    }
  }
}

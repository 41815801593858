@import '../../../assets/scss/theme/colors';
@import '../../../assets/scss/theme/global';

.loading-message {
  align-items: center;
  display: flex;
  font: normal normal 700 16px/18px 'Montserrat', sans-serif;
  justify-content: center;
  margin: $layout-02 0;
  text-align: center;
  width: 100%;
  /*Don't sort*/
  background: -webkit-linear-gradient(-90deg, $primary-color 20%, $secondary-color 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@import '../../assets/scss/theme/colors';
@import '../../assets/scss/theme/global';

.pagination {
  .page-item {
    &.first-page-button {
      .page-link {
        border-radius: $radius-sm 0 0 $radius-sm;
      }
    }

    &.last-page-button {
      .page-link {
        border-radius: 0 $radius-sm $radius-sm 0;
      }
    }

    &.active,
    &.prev-page-button,
    &.next-page-button {
      .page-link {
        border-radius: $radius-sm;
      }
    }

    &.prev-page-button,
    &.next-page-button {
      .page-link span {
        align-self: baseline;
        font-size: 26px;
      }
    }

    .page-link {
      align-items: center;
      background-color: #dae0e34a;
      box-shadow: none;
      color: $primary-gray-color;
      display: flex;
      font: 16px 'Open Sans', sans-serif;
      height: 43px;
      justify-content: center;
      line-height: 21px;
      transition: all 0.3s;
      width: 43px;

      &:hover {
        background-color: #dae0e3a9;
      }
    }

    &.active {
      margin: 0 -5px;
      z-index: 4;

      .page-link {
        background-color: $primary-color;
        color: #fff;
      }

      &.first-page-button {
        margin: 0 -5px 0 0;
      }

      &.last-page-button {
        margin: 0 0 0 -5px;
      }
    }
  }
}

@import './colors';
@import './media';
@import './global';

p {
  color: $primary-gray-color;
  font-weight: normal;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-display: swap;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
}

h1 {
  color: #000;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
}

h2 {
  font-weight: normal;
  font-size: 24px;
}

h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

h4 {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: $layout-01;
}

h1.title-banner {
  font-weight: 500;
  font-size: 90px;
}

h1.title-nav {
  font-weight: 600;
  font-size: 48px;
}

.title-hero {
  color: #fff;
  font: normal normal 500 40px/50px 'Montserrat', sans-serif;

  @include query($media-tablet) {
    font-size: 75px;
    line-height: 85px;
  }

  @include query($media-desktop) {
    font-size: 90px;
    line-height: 100px;
  }
}

.title-section {
  color: $primary-color;
  font-size: 26px;
  font-weight: 600;
  margin-top: $layout-06;
  margin-bottom: $layout-03;
  text-align: center;
  line-height: 36px;

  p {
    font: normal normal 600 18px/22px 'Open Sans', sans-serif;
    letter-spacing: 0.001rem;
    text-align: center;
  }

  @include query($media-tablet) {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: $layout-05;
    margin-top: $layout-07;
  }
}

.subtitle-section {
  color: $primary-gray-color;
  font: normal normal 600 17px/27px 'Open Sans', sans-serif;
  letter-spacing: 0.001em;
  margin-top: 8px;
  margin-bottom: $layout-05;
  text-align: center;
}

.title-sidebar {
  color: $primary-color;
  font-size: 30px;
  font-weight: normal;
  letter-spacing: -0.5px;
  margin-bottom: $layout-03;
}

.font-secondary {
  font-family: 'Open Sans', sans-serif;
}

.text-primary-color {
  color: $primary-color;
}

p.loading-message {
  font: normal normal 700 16px/18px 'Montserrat', sans-serif;
  height: 100%;
  text-align: center;
  width: 100%;
  /*Don't sort*/
  background: -webkit-linear-gradient(-90deg, $primary-color 20%, $secondary-color 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

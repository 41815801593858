@import '../../assets/scss/theme/global';
@import '../../assets/scss/theme/media';

.video-carousel.container {
  padding: 0;
  margin-bottom: $layout-07;
  width: 90%;

  @media (max-width: 992px) {
    max-width: 100%;
    padding: 0;
  }

  .slick-slider.videos-carousel {
    padding: 0;

    .slick-track {
      max-height: unset;
      padding-bottom: 2rem;

      .slick-slide {
        margin: 0;
        padding: 0;

        & > div {
          align-items: flex-start;
          justify-content: center;

          & > div {
            width: min-content;

            &.no-content {
              height: auto;
            }
          }
        }
      }
    }
  }

  ul.social_icons {
    margin-top: $layout-01;

    li:first-child {
      margin-left: 0;
    }
  }

  .videos-page-link__container {
    padding-top: $layout-05;

    @include query($media-tablet) {
      padding-top: $layout-06;
    }

    .button-secondary {
      padding: $spacing-03 $spacing-09;
    }
  }
}

//
// Hero Sections
//

.shards-hero {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  height: 100vh;
  max-height: 900px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &__header {
    z-index: 2;
  }

  // Hero content and footer
  &__content,
  &__footer {
    z-index: 1;
  }

  // Hero content
  &__content {
    margin-top: auto;
    margin-bottom: auto;
  }

  // Hero footer
  &__footer {
    padding: 0.75rem 1.5rem;
  }
}

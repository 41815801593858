@import '../../assets/scss/theme/media';
@import '../../assets/scss/theme/colors';
@import '../../assets/scss/theme/global';

.footer-main {
  padding-bottom: $spacing-10;
  padding-top: $spacing-10;

  @include query($media-desktop-sm){
    padding-bottom: $spacing-07;
  }

  small {
    font-size: 14px;

    &:hover {
      color: $quaternary-color;
    }
  }

  a {
    opacity: 0.6;

    &:hover {
      text-decoration: none;
      opacity: 1;
    }
  }

  #mc_embed_signup_scroll {
    width: 100%;
  }
  
  #mc_embed_signup {
    form {
      width: 100% !important;
    }
  }
}

.footer-main {
  background: $primary-color url('../../assets/images/footer-flag.svg') bottom left no-repeat;
}

.footer-main__nav {
  margin-bottom: $layout-02;

  @include query($media-tablet){
    margin-bottom: 0;
  }
}

.footer-main__actions-text {
  font-size: 14px;
}

.footer-main__copyright {

  img {
    width: 133px;
  }

  .social_icons {
    margin-top: 0;

    li:first-child {
      margin-left: 0;

      @include query($media-tablet) {
        margin-left: 0;
      }
    }

    @include query($media-desktop-sm){
      text-align-last: left;
    }
  }

  .terms {
    display: block;
    margin-bottom: $layout-02;
    font-weight: normal;

    @include query($media-desktop-sm){
      display: inline-block;
      margin-bottom: 0;
      margin-left: $layout-04;
    }
  }

  .Copyright {
    font-size: 14px;
  }
}

.footer-main__form {

  input[type="email"] {
    width: calc(100% - 136px);

    @include query($media-desktop){
      width: 170px;
    }
    
    @include query($media-desktop-lg){
      width: 200px;
    }
  }

  #mc_embed_signup_scroll {
    width: 100%;

    @include query($media-tablet){
      width: 335px;
    }

    @include query($media-desktop-sm){
      width: 300px;
    }

    @include query($media-desktop-lg){
      width: 330px;
    }
  }
}

.footer-main__link--active {
  opacity: 1;
  
  small {
    font-weight: bold !important;
  }
}

#button-suscribe {
  font: normal normal 400 14px/18px 'Open Sans',sans-serif;
}
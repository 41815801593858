//
// Call to Action - Variation 6
//

.shards-cta--6 {
  background-size: $cta-v6-background-size;
  background-position: $cta-v6-background-position;
  background-repeat: $cta-v6-background-repeat;
  background-color: $cta-v6-background-color;
}

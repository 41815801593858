//
// Counter - Variation 2
//

.shards-counter--2 .counter-item__title {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 2px;
}

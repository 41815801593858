@import '../../../assets/scss/theme/colors';
@import '../../../assets/scss/theme/media';
@import '../../../assets/scss/theme/global';

.button-principal,
.button-secondary,
.button-tertiary,
.button-quaternary,
.category__button,
.button-suscribe,
.button-modal {
  border-radius: $radius-lg;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  min-width: max-content;
  padding: $spacing-03 $spacing-06;
  text-decoration: none;

  a {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
    transition: background 0.5s;
  }
}

.button-principal {
  background: $gradient-principal;
  box-shadow: $box-shadow-primary;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &::before {
    background: $gradient-secondary;
    content: '';
    height: 400%;
    position: absolute;
    right: 100%;
    top: 0;
    width: 400%;
    z-index: -1;
    transform: rotate(45deg);
    filter: blur(5px);
    transition: 0.8s ease-in;
  }

  &:hover {
    color: #fff;

    &::before {
      right: -200%;
      transition: 0.8s ease-out;
    }
  }
}

.button-secondary {
  background: $primary-color;
  border: 1px solid $primary-color;
  box-shadow: $box-shadow-primary;
  margin-bottom: 5px;

  &:hover {
    background: $quaternary-color;
    border-color: $quaternary-color;
    color: white;
  }

  &:active {
    box-shadow: none;
    margin-bottom: 0;
    margin-top: 5px;
    transition: 0.2s;
  }
}

.button-tertiary,
.button-quaternary {
  &:hover {
    background: $tertiary-color;
    color: white;
  }
}

.button-tertiary {
  background-color: $dark-tertiary-color;
  box-shadow: $box-shadow-primary;
}

.button-quaternary {
  background-color: #fff;
  color: $primary-color;
}

.button-filter {
  background-color: $tertiary-gray-color;
  border-radius: $radius-lg;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: $layout-01;
  margin-right: $layout-01;
  min-height: 37px;
  min-width: max-content;
  padding: $spacing-03 $spacing-06;
  text-decoration: none;
  transition: background 0.5s;

  @include query($media-desktop-sm) {
    margin-right: $layout-04;
  }

  &:hover {
    background: $quaternary-color;
    color: #fff;
    text-decoration: none;
  }
}

.category__button {
  border-radius: $radius-md;
  box-shadow: $box-shadow-secundary;
  color: $primary-color;
  letter-spacing: 0.0015em;
  line-height: 26px;

  &:hover {
    background: $gradient-secondary;
    color: #fff;
  }

  img {
    margin-right: $layout-01;
    object-fit: cover;
    width: 30px;
  }
}

.button-suscribe {
  background: $secondary-color !important;
  border-radius: 0 $radius-sm $radius-sm 0 !important;
  padding: $spacing-03 $spacing-05;
  width: auto !important;

  &:hover {
    background: lighten($secondary-color, 20%) !important;
  }

  &:focus {
    border-radius: $radius-sm;
    outline: 1px solid #fff;
    outline-offset: -1px;
  }
}

.button-send {
  background: $gradient-secondary;
  border-radius: $radius-lg;
  border: none;
  color: transparent;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;

  &::before {
    background: $gradient-principal;
    color: #fff;
    content: 'Enviar';
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    height: 100%;
    line-height: 38px;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.8s;
    width: 100%;
    z-index: -2;
  }

  &::after {
    color: #fff;
    content: url('../../../../src/assets/images/icons/send.svg');
    position: absolute;
    right: 110%;
    top: 60%;
    transform: translate(50%, -50%);
    transition: 0.5s ease-out;
    width: 50px;
    z-index: -2;
  }

  &:hover {
    color: transparent;

    &::before {
      right: -100%;
      transition: 0.5s ease-out;
    }

    &::after {
      right: 50%;
      transition: 0.5s ease-out;
    }
  }
}

.button--icon {
  margin-bottom: $layout-02;
  padding-right: $spacing-09;
  position: relative;
  border-radius: $radius-lg;

  a {
    color: white;
  }

  &:after {
    content: '';
    width: 24px;
    right: 16px;
    height: 24px;
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  @include query($media-tablet) {
    margin-bottom: 0;
    padding-right: $spacing-09;
    position: relative;
    border-radius: $radius-lg;
  }
}

.button--icon-download {
  &:after {
    background-image: url('../../../assets/images/icons/download.svg');
  }
}

.button--icon-link {
  &:after {
    background-image: url('../../../assets/images/icons/link.svg');
  }
}

.button--icon-doc {
  &:after {
    background-image: url('../../../assets/images/icons/doc.svg');
  }
}

.button--active {
  background-color: $primary-color;
}

.button--disable {
  background-color: $tertiary-gray-color;
}

.button--close {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.button-circular--close {
  border-radius: 50%;
  border: 1px solid #2d4481;
  height: 37px;
  width: 37px;

  &:hover {
    background: #2d4481;

    svg path {
      fill: #fff;
    }
  }
}

.button--arrow {
  background-image: url('../../../assets/images/icons/arrow.svg');
  background-position: 55% 50%;
  background-repeat: no-repeat;
}

.button-circular--shadow {
  background-color: #fff;
  box-shadow: 0 0 0 0.7em #f9f9f9, 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  height: 32px;
  outline: none;
  width: 32px;
  margin-top: 15px;

  &.left-button {
    transform: rotate(180deg);
    margin: 0 2px 2px 0;
  }

  img {
    margin: 0 0 2px 2px;
  }
}

.button-modal {
  background-color: #fff;
  box-shadow: $box-shadow-primary;
  color: $primary-color;
}

.btn-download {
  background-color: #fff;
  border-radius: $radius-lg;
  border: 0;
  color: $primary-color;
  font: normal normal 600 14px/18px 'Open Sans', sans-serif;
  letter-spacing: 0.0025rem;
  margin: 0 $layout-01 $layout-01 0;
  padding: $spacing-03;
  transition: all 0.3s ease;

  @include query($media-mobile) {
    padding: 0.4rem $spacing-05;
  }

  &:hover {
    background-color: $primary-color;
    color: #fff;
    text-decoration: none;

    svg path {
      stroke: #fff;
    }
  }

  span {
    display: none;

    @include query($media-mobile) {
      display: block;
    }
  }

  svg {
    @include query($media-mobile) {
      margin-left: 10px;
    }

    path {
      transition: stroke 0.3s ease;
      stroke: $primary-color;
    }
  }

  &.right-align {
    position: absolute;
    right: 0;
  }
}

button.btn-no-styles {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0;
  padding: 0;
  text-transform: none;
}
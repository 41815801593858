$media-mobile: 576px;
$media-tablet: 768px;
$media-desktop-sm: 992px;
$media-desktop: 1024px;
$media-desktop-lg: 1200px;


// respond is the name of your mixin

// Query
// $breakpoint: puede ser una variable o un valor.
// 
// .selector {
//  @include query($var) {
//     a { color: pink; }
//  }    
// }

@mixin query($breakpoint) {
  @media only screen and ("min-width": $breakpoint) {
      @content;
  }
}
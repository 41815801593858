@import '../../../../assets/scss/theme/global';

.social-media {
  padding-bottom: $spacing-04;
}

.social-media-link {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: $box-shadow-secundary;
  margin: 0 $layout-01 0 0;

  &:hover {
    background-color: #f1f1f1cb;
  }

  a,
  button {
    background: transparent;
    border-radius: 50%;
    border: 0;
    outline: 0;
    padding: 0;

    .logo-container svg,
    .logo-container img {
      height: 30px;
      padding: 6px;
      width: 30px;
    }
  }
}

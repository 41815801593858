@import '../../assets/scss/theme/colors';

.slick-slider {
  .slick-slide {
    justify-content: center;
  }

  .slick-dots {
    li {
      button::before {
        color: $primary-color;
        font-size: 12px;
        opacity: 0.7;
        transition: font-size 0.3s ease-in-out;
      }

      &.slick-active,
      &:not(.slick-active):last-of-type {
        button::before {
          font-size: 18px;
          opacity: 1;
        }
      }

      &.slick-active ~ li:last-of-type {
        button::before {
          font-size: 12px !important;
          opacity: 0.7;
        }
      }
    }
  }
}

@import '../../../../assets/scss/theme/media';
@import '../../../../assets/scss/theme/global';

.event-tabs {
  border-bottom: 1px solid black;
  margin: 20px auto 30px auto;
  padding-bottom: 10px;

  .categories-toggler {
    background: transparent;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: -0.02em;
    outline: none;

    &:hover img {
      filter: brightness(180%);
    }

    img {
      margin: 0 0 2px 10px;
      transform: rotate(90deg);
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: $layout-01 0;
    padding: 0;

    @include query($media-tablet) {
      display: block;
      margin: 0;
      text-align: center;
    }

    &.overflow-scroll-x {
      &::-webkit-scrollbar {
        display: none;
      }
    }

    li {
      display: inline-block;
      margin: 0 5px;

      .button-filter {
        margin: $spacing-03 0;

        @include query($media-tablet) {
          margin: $spacing-03;
        }
      }
    }
  }
}

@import '../theme/global';
@import '../theme/media';

.sidebar-item {
  border-radius: $radius-md;
  box-shadow: $box-shadow-primary;
  margin-bottom: $layout-02;

  &:last-child {
    margin-bottom: $layout-07;

    @include query($media-tablet) {
      margin-bottom: $layout-02;
    }
  }
}

.lazyload-wrapper,
.sidebar-item__img {
  border-radius: $radius-md 0 0 $radius-md;
}

.sidebar-item__img {
  height: 100%;
  object-fit: cover;
  transition: height 1s, filter 1s ease-in-out;
  width: 100%;

  &:hover{
    height: 120% !important;
  }
}
.cover-img {
  object-fit: cover;
  object-position: center;
}
.sidebar-item__body {
  padding: $spacing-05 $spacing-05 $spacing-05 0;

  a:hover {
    text-decoration: none;
  }

  h4 {
    font-size: 16px;
    min-height: 60px;
  }
}

.sidebar-item__icon {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  bottom: 13px;
  box-shadow: 0px 1px 5px rgba(90, 97, 105, 0.5);
  display: flex;
  height: 37px;
  justify-content: center;
  left: 15px;
  position: absolute;
  width: 37px;

  img {
    margin: 0 auto;
    width: 25px;
  }
}

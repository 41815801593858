//
// Features - Variation 12
//

.shards-features--12 {
  .image-placeholder {
    min-height: 400px;
    max-width: 600px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      min-width: 100%;
    }
  }
}

@import '~slick-carousel/slick/slick';
@import '../../../../assets/scss/theme/colors';
@import '../../../../assets/scss/theme/global';
@import '../../../../assets/scss/theme/media';
@import '~slick-carousel/slick/slick-theme';
.slick-slider {
  background: transparent;
  padding: 0;

  div {
    box-shadow: none;
  }

  .slick-track {
    align-items: stretch;
    display: flex;
    overflow: hidden;

    @include query($media-desktop) {
      padding: 0;
    }

    .slick-slide {
      align-items: stretch;
      display: flex;
      height: unset;
      margin: 2em 0;
      padding: 0 0 0 0.3rem;

      & > div {
        align-items: stretch;
        display: flex;
        flex-direction: column;

        & > div {
          height: 100%;
        }
      }
    }
  }

  .slick-arrow {
    z-index: 1;

    &.slick-prev,
    &.slick-next {
      height: 44px;
      top: 55%;
      width: 44px;

      &:before {
        color: $primary-color;
        display: none;
        font-size: 44px;

        @include query($media-desktop) {
          display: block;
        }

        &:hover {
          color: $quaternary-accent-color;
        }
      }
    }

    &.slick-prev {
      @include query($media-desktop) {
        left: -32px;
      }

      @include query($media-desktop-lg) {
        left: -60px;
      }
    }

    &.slick-next {
      right: 10px;

      @include query($media-desktop) {
        right: -28px;
      }

      @include query($media-desktop-lg) {
        right: -60px;
      }
    }

    &:not(.slick-disabled) {
      &:before {
        opacity: 1;
      }
    }
  }

  @include query($media-desktop) {
    padding: 0 $spacing-06;
  }

  @include query($media-desktop-lg) {
    padding: 0 $spacing-01;
  }
}

.slick-slider.videos-carousel {
  .slick-track {
    max-height: 270px;

    .slick-slide {
      & > div {
        &,
        .video-player,
        .row {
          align-items: center;
          width: 100%;
        }

        .video-player {
          display: flex;
          height: 100%;
          margin-bottom: 0;

          .video-preview {
            padding: 0;

            .video-preview__img {
              object-fit: cover;
              height: 250px;
              width: 340px;
            }
          }
        }
      }
    }
  }
}

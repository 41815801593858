@import '../../../../assets/scss/theme/colors';
@import '../../../../assets/scss/theme/media';
@import '../../../../assets/scss/theme/global';

.submenu {
  display: block;
  left: 0;
  padding: 0 0 $layout-02 $layout-01;
  position: relative;
  top: 100%;
  width: max-content;

  @include query($media-desktop-sm) {
    background-color: #fff;
    display: none;
    max-width: 200px;
    opacity: 0;
    padding: $layout-03 $layout-02;
    position: absolute;
  }

  &:hover ~ .navbar-nav a {
    color: $tertiary-color;

    svg path {
      fill: $tertiary-color;
    }
  }

  .content {
    max-width: 320px;
  }

  .link-list {
    margin: 0;
    text-align: left;

    &__link {
      margin-bottom: $layout-01;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        color: #fff;
        font: normal normal normal 16px 'Open Sans', sans-serif;
        letter-spacing: 0.005rem;
        line-height: 1.45;

        @include query($media-desktop-sm) {
          color: $primary-gray-color;

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

@import '../../../assets/scss/theme/global';

.horizontal-video-card {
  border-radius: $radius-md;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  margin-bottom: $layout-02;
  overflow: hidden;

  &,
  &:hover {
    color: #000;
  }

  &__image {
    img {
      object-fit: cover;
    }

    button.button-play {
      img {
        margin-left: 2px;
        width: 10px;
      }
    }
  }

  &__title {
    padding: $layout-01;

    .title {
      color: #000;
      font-family: 'Open Sans', sans-serif;
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}

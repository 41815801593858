@import '../../assets/scss//theme/colors';
@import '../../assets/scss//theme/global';
@import '../../assets/scss//theme/media';

.video-grid {
  &:not(&:last-of-type) {
    margin-bottom: $layout-02;
  }

  .grid-title-container {
    .grid-title {
      color: $primary-gray-color;
      font: normal normal 600 1.5rem 'Montserrat', sans-serif;
      letter-spacing: 0.01rem;
      line-height: 1.3;
      margin-bottom: 0;
      padding: $spacing-09 0 $spacing-07 0;

      @include query($media-desktop) {
        font-size: 1.875rem;
        margin-left: -$layout-01;
      }
    }

    .link-primary {
      background: none;
      border: none;
      width: max-content;
    }
  }

  .content-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @include query($media-desktop) {
      justify-content: flex-start;
    }

    .video-card {
      margin-bottom: $layout-03;

      @include query($media-tablet) {
        margin-right: $layout-03;
      }

      &-body {
        max-width: 260px;
      }
    }
  }
}

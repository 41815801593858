//
// Call to Action - Variation 7
//

.shards-cta--7 {
  background-color: $cta-v7-background-color;
  background-size: $cta-v7-background-size;
  background-repeat: $cta-v7-background-repeat;
  background-position: $cta-v7-background-position;

  @include media-breakpoint-down(md) {

    form input,
    form button {
      width: 100%;
      min-width: 100% !important;
    }
  }

  @include media-breakpoint-down(sm) {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #EEE0D1;
      opacity: .7;
    }
  }
}

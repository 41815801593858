@import '../../assets/scss/theme/colors';

.placeholder-bg {
  overflow: hidden;
  
  &,
  img {
    background-color: $primary-color;
    height: 100%;
    width: 100%;
  }
}

@import "./colors.scss";
*,
*:before,
*:after {
  box-sizing: border-box;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

html,
header,
main,
section,
footer {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

// inputs

textarea {
  resize: none;
  overflow: hidden;
  }
  
  // reset  lists
  ul {
    list-style-type: none;
    padding: 0;
  }

//spacing inside elementes 
$spacing-01: .125rem;
$spacing-02: .25rem;
$spacing-03: .5rem;
$spacing-04: .75rem;
$spacing-05: 1rem; //16px
$spacing-06: 1.5rem;//24px
$spacing-07: 2rem; //32px
$spacing-08: 2.5rem; //40px
$spacing-09: 3rem; //48px
$spacing-10: 6rem; //96px

//spacing outside elementes 
$layout-01: 1rem; //16px
$layout-02: 1.5rem; //24px
$layout-03: 2rem; //32px
$layout-04: 3rem; //48px
$layout-05: 4rem; //64px
$layout-06: 6rem; //96px
$layout-07: 10rem; //160px

// links

.link-primary,
.link-secondary {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    text-decoration: none;
  }
}

.link-primary {
  color: $primary-color;
  font-weight: 700;

  &:hover {
    color: lighten($primary-color, 20%) !important;
  }
}

.link-secondary {
  color: $tertiary-accent-color;
}

//border radius

$radius-sm: 8px;
$radius-md: 15px;
$radius-lg: 30px;

//box shadow

$box-shadow-primary: 0px 2px 0px rgba(90, 97, 105, 0.11), 0px 4px 8px rgba(131, 194, 226, 0.18), 0px 10px 10px rgba(33, 61, 122, 0.17), 0px 7px 70px rgba(90, 97, 105, 0.1);
$box-shadow-secundary: 0px 2px 0px rgba(90, 97, 105, 0.11), 0px 4px 8px rgba(90, 97, 105, 0.12), 0px 10px 10px rgba(90, 97, 105, 0.06), 0px 7px 70px rgba(90, 97, 105, 0.1);
$box-shadow-tertiary: 0 0 0 0.5em #f9f9f9, 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);

//gradientss 

$gradient-principal: linear-gradient(119.65deg, #83C2E2 9.26%, #203B77 118.87%);
$gradient-secondary: linear-gradient(126.76deg, #92D9FC 24.08%, #274891 107.46%);
$gradient-tertiary: linear-gradient(279.97deg, rgba(131, 194, 226, 0.1) 1.62%, rgba(224, 215, 195, 0.2) 85.48%);

.overflow-scroll-x {
  overflow-x: scroll;
}
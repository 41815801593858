// Overrides

$google-fonts-import: 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600|Roboto+Mono|Roboto:300,400,500,700' !default;

$font-family-system-first: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;

$light: #f9fafc;
$border-color: #ececec; // $border-color: #dcdfe1;

$dark: #0c0d0e;


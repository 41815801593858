@import '../../assets/scss/theme/colors';
@import '../../assets/scss/theme/global';
@import '../../assets/scss/theme/media';

.videos-heading-section {
  align-items: center;
  background-color: #dae0e34a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: $layout-06 + $layout-02;

  h1.videos__title {
    letter-spacing: 0.01rem;
    line-height: 1.3;
    margin: $layout-06 0 0 0;
  }

  .carousel-container {
    max-width: 100vw;
    width: 100%;

    @include query(1348px) {
      max-width: 1348px;
    }
  }
}

.video-grid-container {
  padding: 7.2rem 0 $layout-07 0;

  .event-tabs {
    margin: 0;
    padding: 0;
    width: 100vw;

    @include query($media-tablet) {
      width: 100%;
    }

    ul {
      display: flex;

      li {
        padding-right: $layout-03;

        &:last-of-type {
          padding-right: $layout-01;
        }

        &,
        .button-filter {
          margin: 0;
          min-width: fit-content;
        }
      }
    }
  }
}

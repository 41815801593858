@import '../../../../assets/scss/theme/global';
@import '../../../../assets/scss/theme/media';

.simple-video-card {
  border-radius: $radius-md;
  height: 296px;
  overflow: hidden;
  position: relative;
  width: 375px;

  @include query($media-tablet) {
    height: 350px;
    width: 415px;
  }

  .cover-img {
    min-height: 350px;
    height: 350px;
    min-width: 415px;
    width: 415px;
  }

  .card-content {
    bottom: 1.3rem;
    left: 1.3rem;
    position: absolute;

    p {
      color: #fff;
      font: normal normal bold 1.875rem 'Montserrat', sans-serif;
      line-height: 1.3;
      margin: 0;
    }
  }
}

@import '../../assets/scss/theme/global';
@import '../../assets/scss/theme/media';

.stick-network {
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
    0 0 1px rgba(0, 0, 0, 0.04);
  display: flex;
  height: 3.125rem;
  justify-content: center;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  z-index: 15;

  &.show {
    opacity: 1;
  }

  @include query($media-tablet) {
    border-radius: 0 $radius-md $radius-md 0;
    display: block;
    height: fit-content;
    left: 0;
    opacity: 1;
    padding: $spacing-04 0;
    top: 85%;
    transform: translateY(-50%);
    width: 4.25rem;
  }

  .stick-item-wrapper {
    align-items: center;
    height: 100%;
    margin: 0 $spacing-03;

    @include query($media-tablet) {
      padding: $spacing-02;
    }

    .stick-item {
      border-radius: $radius-md;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
        0 0 1px rgba(0, 0, 0, 0.04);
      height: 2rem;
      padding: $spacing-03;
      width: 2rem;

      &,
      & > a {
        display: flex;
        justify-content: center;
      }

      @include query($media-tablet) {
        border-radius: $radius-sm;
        box-shadow: none;
        cursor: pointer;
        height: unset;
        padding: $spacing-03;
        width: unset;

        &:hover {
          background: #eaeaea;
        }
      }

      img {
        height: 100%;
      }
    }
  }
}

@import '../../../assets/scss/theme/colors';
@import '../../../assets/scss/theme/global';
@import '../../../assets/scss/theme/media';

.video {
  padding: $layout-06 0 $layout-07 0;

  & > p {
    margin-bottom: $spacing-03;
  }

  &__player {
    aspect-ratio: 16/9;
  }

  &__title {
    padding-top: $layout-04;
  }

  &__date {
    font: normal normal bold 19px 'Open Sans', sans-serif;
    line-height: 1.3;
    letter-spacing: 0.0015rem;
    color: $secondary-gray-color;
  }

  .social-media {
    padding: $spacing-03 0;
  }

  &__content {
    padding-top: $layout-02;
  }

  .videos-sidebar {
    padding-top: $layout-03;

    @include query($media-desktop-sm) {
      padding-top: 0;
    }
  }
}

@import '../../assets/scss/theme/colors';
@import '../../assets/scss/theme/media';
@import '../../assets/scss/theme/global';

.counter-component {
  background-color: $primary-color;
  border-radius: $radius-md;
  width: 90%;
  z-index: 10;
  position: relative;
  transform: translate(0, -10%);

  @include query(375px) {
    transform: translate(0, -16%);
  }

  @include query($media-desktop-sm) {
    transform: translate(0, -30%);
  }

  &.shards-counter--1 {
    .container {
      padding: 0 $spacing-03;

      @include query($media-mobile) {
        padding: 0 $spacing-05;
      }
    }

    .counter__title {
      font-size: 34px;
      font-weight: normal;
      line-height: 39px;
      margin: 0 0 $spacing-04 0;

      @include query($media-desktop-lg) {
        font-size: 48px;
        font-weight: 600;
        line-height: 63px;
      }
    }

    .dates-container {
      @include query($media-desktop-lg) {
        width: 100%;
      }

      .counter-item {
        &:not(:last-of-type) {
          padding-bottom: $layout-01;

          @include query($media-tablet) {
            padding-bottom: 0;
          }
        }

        @include query($media-desktop-lg) {
          padding: 0 $spacing-02;
        }

        &__title {
          color: #fff;
          font: 18px 'Montserrat', sans-serif;
          letter-spacing: 0.0015rem;
          line-height: 32px;
          text-transform: initial;
          font-weight: bold;
          margin-bottom: 0;

          @include query($media-desktop-sm) {
            font-size: 19px;
          }

          @include query($media-desktop-lg) {
            font-size: 19px;
          }
        }
      }
    }
  }
}

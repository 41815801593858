// Shards Galleries

.shards-gallery__item {
  position: relative;

  &-overlay {
    transition: all 200ms ease-in-out;
    background: rgba(0, 0, 0, .15);
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      left: 50%;
      top: 50%;
      transform: scale(1.5);
      margin-top: -10px;
      margin-left: -10px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M-2-2h24v24H-2z'/%3E%3Cpath d='M11 5H9v4H5v2h4v4h2v-4h4V9h-4V5zm-1-5C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &:hover {
    .shards-gallery__item-overlay {
      opacity: 1;
    }
  }
}

$primary-color: #213D7A;
$secondary-color: #E9052D;
$tertiary-color: #83C2E2;
$quaternary-color: #4F72BE;

$primary-accent-color: #9C8C5C;
$secondary-accent-color: #E0D7C3;
$tertiary-accent-color: #8E2637;
$quaternary-accent-color: #242A51;

$primary-gray-color: #5E626E;
$secondary-gray-color: #9599A4;
$tertiary-gray-color: #C0C3CA;

$light-text: $secondary-gray-color;
$dark-tertiary-color: #5799BA;
$secondary-text: #5a6169;
